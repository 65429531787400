import { ReactNode } from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

interface InputEmailProps {
  name: string;
  label: string;
  dataTestid: string
  options?: {
    required?: boolean;
    disabled?: boolean;
    noEmailCheckbox?: boolean;
  }
}

function InputEmail({
  name,
  label,
  dataTestid,
  options,
}: InputEmailProps): ReactNode {
  const { t } = useTranslation();

  return (
    <Form.Item
      name={name}
      label={label}
      rules={options?.disabled ? [] : [
        {
          required: options?.required,
          message: t('EMAIL_REQUIRED'),
        },
        {
          type: 'email',
          message: t('WRONG_FORMAT_EMAIL'),
        },
      ]}
      style={options?.noEmailCheckbox ? {
        marginBottom: 5, // réduit l'espace entre le form item et la checbox si présente.
      } : {}}
    >
      <Input data-testid={dataTestid} disabled={options?.disabled} />
    </Form.Item>
  );
}

export default InputEmail;
