import { Card, Select, Typography, Form, Row, Col, Flex } from 'antd';
import { PickupPointCardProps } from '../../interfaces/PickupPointCardProps';
import { distanceParser } from '@shopopop/backoffice-frontend-utils';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

function PickupPointCard({ readMode, distance, unit, options, distanceError }: PickupPointCardProps) {
  const { t } = useTranslation();
  const formattedDistance = distanceParser({ value: distance, unit });

  return (
    <Card title={t('ORDER_PUP_TITLE')}>
      {readMode ? (
        <Row gutter={[16, 16]}>
          <Col xs={24} md={12}>
            <Text type="secondary">
              {t('PUP_FIELD')} : <Text>{options[0].label}</Text>
            </Text>
          </Col>
          {formattedDistance && (
            <Col xs={24} md={12}>
              {distanceError && (
                <Flex vertical>
                  <Text type={'danger'}>{t('DELIVERY_DISTANCE')} : {formattedDistance}</Text>
                  <Text type={'danger'}>{t('DISTANCE_TOO_LONG')}</Text>
                </Flex>
              )}
              {!distanceError && (
                <Text>
                  <Text type={'secondary'}>{t('DELIVERY_DISTANCE')} :</Text>
                  <Text> {formattedDistance}</Text>
                </Text>
              )}
            </Col>
          )}
        </Row>
      ) : (
        <>
          <Form.Item
            label={t('PUP_FIELD')}
            name="pickupPoint"
            rules={[
              {
                required: true,
                message: t('PUP_FIELD_REQUIERED_ERROR'),
              },
              {
                validator: () => {
                  if (distanceError) {
                    return Promise.reject(new Error(t('DISTANCE_TOO_LONG_ERROR')));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Select
              labelInValue
              options={options}
              defaultValue={options[0]}
              placeholder={t('SELECT_OUR_PUP_FIELD')}
              style={{ width: '100%' }}
            />

          </Form.Item>
          {formattedDistance && (
            <Text type="secondary">
              {t('DELIVERY_DISTANCE')} :
              {!distanceError && <Text> {formattedDistance}</Text>}
              {distanceError && <Text type={'danger'}> {formattedDistance}</Text>}
            </Text>
          )}
        </>
      )}
    </Card>
  );
}

export default PickupPointCard;
